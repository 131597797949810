import '../../../assets/icons/closed.svg';
import '../../../assets/icons/dropdown-arrow.svg';
import '../../../assets/icons/loop.svg';
import '../../../assets/icons/lightning.svg';
import '../../../assets/icons/plus.svg';
import '../../../assets/icons/cell-select-arrow.svg';
import '../../../assets/icons/logo.svg';
import '../../../assets/icons/bread-crump-arrow.svg';
import '../../../assets/icons/preview-icon.svg';
import '../../../assets/icons/shield.svg';
import '../../../assets/icons/eye.svg';
import '../../../assets/icons/heart.svg';
import '../../../assets/icons/safe.svg';
import '../../../assets/icons/procent.svg';
import '../../../assets/icons/shield_check.svg';
import '../../../assets/icons/bar_graph.svg';
import '../../../assets/icons/calculator.svg';
import '../../../assets/icons/calendar.svg';
import '../../../assets/icons/coin_down.svg';
import '../../../assets/icons/refresh.svg';
import '../../../assets/icons/rub.svg';
import '../../../assets/icons/document.svg';
import '../../../assets/icons/arrow_right.svg';
import '../../../assets/icons/moneybag.svg';
import '../../../assets/icons/checkmark.svg';
import '../../../assets/icons/digital_banknotes.svg';
import '../../../assets/icons/wallet.svg';
import '../../../assets/icons/notebook_waves.svg';
import '../../../assets/icons/support_mini.svg';
import '../../../assets/icons/support.svg';
import '../../../assets/icons/clock.svg';
import '../../../assets/icons/house.svg';
import '../../../assets/icons/coat_of_arms.svg';
import '../../../assets/icons/globe.svg';
import '../../../assets/icons/plane.svg';
import '../../../assets/icons/red_cross.svg';
import '../../../assets/icons/heart-black.svg';
import '../../../assets/icons/document-black.svg';
import '../../../assets/icons/letters.svg';
import '../../../assets/icons/green-calendar.svg';
import '../../../assets/icons/green-refresh.svg';
import '../../../assets/icons/green-bar_graph.svg';
import '../../../assets/icons/green-coin_down.svg';
import '../../../assets/icons/green-rub.svg';
import '../../../assets/icons/green-wallet.svg';
import '../../../assets/icons/green-wallet2.svg';
import '../../../assets/icons/green-case.svg';
import '../../../assets/icons/green-ula.svg';
import '../../../assets/icons/green-computer.svg';
import '../../../assets/icons/green-shield_check.svg';
import '../../../assets/icons/email.svg';
import '../../../assets/icons/letter.svg';
import '../../../assets/icons/play.svg';
import '../../../assets/icons/twitter-icon.svg';
import '../../../assets/icons/vk-icon.svg';
import '../../../assets/icons/green-moneybag.svg';
import '../../../assets/icons/green-checkmark.svg';
import '../../../assets/icons/green-clock.svg';
import '../../../assets/icons/checkbox-mark.svg';
import '../../../assets/icons/union.svg';
import '../../../assets/icons/people.svg';
import '../../../assets/icons/green-document.svg';
import '../../../assets/icons/green-email.svg';
import '../../../assets/icons/heart-hand.svg';
import '../../../assets/icons/green-play.svg';
