import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../components/images/Sprits/icons';
import '../modules/Nquestionable/questionable';
import '../assets/global-style/style.scss';
import Cookie from '../modules/Сookie/Сookie';
import {sendEvent, setConfig, setMeta, setProfile } from '@sbol/clickstream-agent';

(function () {
    const $cookie = document.querySelector('.js-ns-cookie');

    if ($cookie) {
        new Cookie($cookie);
	}

	let hash = window.location.hash;

	setConfig({
		reportUrl: 'https://ext.clickstream.sberbank.ru/metrics/ecosystem/sberinsur'
		// остальные параметры опциональны, см. официальную документацию
	});

// Установка параметров приложения и устройства
	setMeta({
		apiKey: 'b960b139f9bd987cde8f63bf5f78c37522f4993888735076ed1dcfd9299810ce'
		// остальные параметры опциональны, см. официальную документацию
	});

	setProfile({
		hashUserLoginId: hash,
		appVersion: '1.0.0'
	});

	let headerPhone = document.getElementById("headerphone");
	headerPhone.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/8 495 500-55-50/клик',
		})
		;
	});

	let header900 = document.getElementById("header900");
	header900.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/900/клик',
		});
	});

	let footer900 = document.getElementById("footer900");
	footer900.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Футер/900/клик',
		});
	});

	let footerphone = document.getElementById("footer900");
	footerphone.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Футер/8 495 500-55-50/клик',
		});
	});

	let allPrograms = document.getElementById("all-programs");
	allPrograms.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/Все программы/клик',
		});
	});

	let accumulation = document.getElementById("accumulation");
	accumulation.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/Накопления/клик',
		});
	});

	let investments = document.getElementById("investments");
	investments.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/Инвестиции/клик',
		});
	});

	let lifeHealth = document.getElementById("life-health");
	lifeHealth.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Хедер/Жизнь и здоровье/клик',
		});
	});

	let bvbBanner = document.getElementById("bvb-banner");
	bvbBanner.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Билет В Будущее/Подробнее/клик',
		});
	});

	let lichnyiPrioritet = document.getElementById("lichnyi-prioritet");
	lichnyiPrioritet.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Личный приоритет/Оформить/клик',
		});
	});

	let garantCapital = document.getElementById("garant-capital");
	garantCapital.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Гарант капитал/Подробнее/клик',
		});
	});

	let uprCapital = document.getElementById("upr-capital");
	uprCapital.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Управляемый капитал/Подробнее/клик',
		});
	});

	let formulaBanner = document.getElementById("formula-banner");
	formulaBanner.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Формула жизни/Подробнее/клик',
		});
	});

	let coprBanner = document.getElementById("copr-banner");
	coprBanner.addEventListener("click", function() {
		sendEvent({
			eventCategory: 'sberinsur',
			value: '',
			eventAction: 'Главная страница/Баннер Корпоративное страхование/Подробнее/клик',
		});
	});

	console.log('global init');
    console.log('test init');
})();
