// import './questionable.scss';
// import Api from '../../requests/Api';
//
// (function () {
//     const request = new Api();
//     const questBlock = document.querySelector('.js-ns-questionable');
//     let block;
//     let questions;
//     // let questionCount = 0;
//
//     function questTemplate(el) {
//         let listItems = ``;
//         if (el.questions) {
//             el.questions.forEach((quest) => {
//                 listItems =
//                     listItems +
//                     `
// 					<div class="radiobutton__item">
// 						<input id="${quest.id}" type="radio" class="radiobutton__input js-radiobutton-input visually-hidden"  name="Reason" />
// 						<label for="${quest.id}" class="radiobutton__name radiobutton__name--amount">
// 							<div class="radiobutton__window">
// 								<div class="radiobutton__circle"></div>
// 							</div>
// 							<div class="radiobutton__text">${quest.name}</div>
// 						</label>
// 					</div>
// 					`;
//             });
//         }
//         let listTemplate = `
// 				<div class="radiobutton__list">
// 					${listItems}
// 				</div>
// 				`;
//         const template = `<div  data-id="${el.id}" class="ns-form js-ns-form" >
// 			<div class="ns-form__el">
// 				<div class="ns-form__head" >
// 				<p class="ns-form__head__text">${el.name}</p>
// 				</div>
// 				<div class="ns-cell ns-cell__p20 js-ns-cell" data-class="RadioButton" data-name="RadioButton">
// 				<div class="radiobutton js-radiobutton">
// 					${listTemplate}
// 				</div>
// 				<div class="ns-cell__error-message js-error-message">Выберите значение</div>
// 				<button class="ns-form__send" type="submit">Ответить</button>
// 				</div>
// 				</div>
// 			</div>`;
//         return template;
//     }
//
//     function successfulTemplate() {
//         const template = `
// 			<div class="ns-form__el">
// 				<div class="ns-form__head" >
// 					<p class="ns-form__head__text">Спасибо за прохождение опроса</p>
// 				</div>
// 				<div class="ns-cell">
// 					<div class="questionable__successful js-questionable-successful">
// 						<button class="ns-form__send js-questionable-close" type="button">Закрыть</button>
// 					</div>
// 				</div>
// 			</div>
// 			`;
//         return template;
//     }
//
//     setTimeout(() => {
//         request.getQuestions().then((res) => {
//             questions = res;
//             block = questTemplate(questions[0]);
//             questBlock.insertAdjacentHTML('beforeend', block);
//             !localStorage.getItem('isQuestionableCompleted') && questBlock.classList.remove('hidden');
//         });
//     }, 10000);
//
//     questBlock.addEventListener('submit', sendAnswers);
//
//     function sendAnswers(event) {
//         event.preventDefault();
//         const form = event.target;
//         const formData = new FormData();
//         let inputs = form.querySelectorAll('input:checked');
//         let quest = form.querySelector('.js-ns-form');
//         console.log('quest', quest);
//         let idQuest = quest.getAttribute('data-id');
//         let idAnswer;
//         inputs.forEach((input) => {
//             idAnswer = input.id;
//         });
//         // let answerData = {
//         //     question_id: idQuest,
//         //     answer_id: idAnswer,
//         //     page: window.location.href,
//         // };
//
//         formData.append('question_id', idQuest);
//         formData.append('answer_id', idAnswer);
//         formData.append('page', window.location.href);
//
//         request.sendAnswers(formData).then((res) => {
//             // questionCount++;
//             questBlock.innerHTML = '';
//
//             // if (questionCount <= 2) {
//             //     block = questTemplate(questions[questionCount]);
//             //     questBlock.insertAdjacentHTML('beforeend', block);
//             // } else {
//             //     questBlock.insertAdjacentHTML('beforeend', successfulTemplate());
//             //     const buttonClose = questBlock.querySelector('.js-questionable-close');
//             //     buttonClose.addEventListener('click', () => {
//             //         questBlock.classList.add('hidden');
//             //     });
//             //     localStorage.setItem('isQuestionableCompleted', true);
//             // }
//             questBlock.insertAdjacentHTML('beforeend', successfulTemplate());
//             const buttonClose = questBlock.querySelector('.js-questionable-close');
//             buttonClose.addEventListener('click', () => {
//                 questBlock.classList.add('hidden');
//             });
//             localStorage.setItem('isQuestionableCompleted', true);
//         });
//     }
// })();
