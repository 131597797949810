import './cookie.scss';

export default class Cookie {
    constructor($cookie) {
        this.$cookie = $cookie;
        this.$button = this.$cookie.querySelector('.js-ns-cookie-button');

        this.init();
    }

    init() {
        this.$button.addEventListener('click', this.setCookie.bind(this));

        if (localStorage.getItem('isCookieSet') !== 'set') {
            this.showCookie();
        }
    }

    setCookie() {
        localStorage.setItem('isCookieSet', 'set');
        this.$cookie.style.display = 'none';
    }

    showCookie() {
        this.$cookie.style.display = 'block';
    }
}
